export const BASE_URL = 'api/v1/admin/'
export const LOGIN_URL = 'auth/login/'
export const USER_PROFIL_URL = 'users/profile'
export const REGISTER_URL = 'auth/register'
export const CLASS_LIST_URL = 'schedules'
export const CLASS_TIMES_URL = 'schedules/times'
export const ROOM_LIST_URL = 'rooms'
export const ROOM_BANNER_LIST_URL = 'rooms'
export const HERO_LIST_URL = 'rooms'
export const SUBMIT_ROOM_URL = 'rooms'
export const SUBMIT_CLASS_URL = 'rooms'